const setFeatureToggles = require('./featureToggles')

const hostedZoneId = 'Z09409813QZHFYXOVWMAX'
const domainName = 'ssr.cscshopfront.com'
const environmentName = 'production'

const Layer0DnsRecordName = 'uat.onekingslane.com'
const Layer0DnsRecordType = 'A'
const Layer0DnsRecordValue = [
  '208.69.180.11',
  '208.69.180.12',
  '208.69.180.13',
  '208.69.180.14',
].join(',')
const Layer0DnsSslChallengeName = '_acme-challenge.uat.onekingslane.com'
const Layer0DnsSslChallengeValue = '_acme-challenge.xdn-validation.com'
const BigCommerceDnsSslChallengeName = '_171DE01A775451186B9D4D6718CF3A98.uat.onekingslane.com'
const BigCommerceDnsSslChallengeValue = '4A7E5161F014FF21D62A0D748F8EAECF.C22EEC10EB1BFA1BA02C4877C5B1FAAB.626b10d42da89.comodoca.com'
const MerchantHostedZoneId = 'Z03800471ZSBSJFE7HPGZ'
const PublicCdnDnsName = 'okl-cdn.cscshopfront.com'
const PublicCdnHostedZoneId = 'Z09409813QZHFYXOVWMAX'
const StaticsBucketDnsName = 'statics-sf-ssr-us-east-1-872388417399-production.s3.amazonaws.com'
const CDN_PREFIX = `https://${PublicCdnDnsName}`

module.exports = {
  CITIZENS_SCRIPT: 'https://toolbox.jifiti.com/Widgets/1.0.4/jifiti-widget.min.js',
  CITIZENS_WIDGET_KEY: 'a6e8e1558d49460fa167787d944e95cd',
  AWS_REGION: 'us-east-1',
  AWS_PROFILE: 'zgallerie',
  CHECKOUT_DOMAIN: '/checkout-frame',
  CHECKOUT_IDENTIFIER: 'okl-production',
  DOMAIN_NAME: domainName,
  ENVIRONMENT_NAME: environmentName,
  CONFIG_FILE: 'production.json',
  RECREATE_URL: null,
  CONTENT_PREVIEW: false,
  CONTENT_API: 'https://diuhh8z0rj.execute-api.us-east-1.amazonaws.com/Prod/v1', // diuhh8z0rj.execute-api.us-east-1.amazonaws.com
  SEARCH_API: 'https://gfkldh9057.execute-api.us-east-1.amazonaws.com/Prod',
  SHOP_ORIGIN: 'https://www.onekingslane.com',
  CDN_PREFIX,
  SHOP_HASH: 'xx4tpvew0y',
  GA_TRACKING_ID: 'GTM-WB5VZMG',
  FACEBOOK_PIXEL_ID: '283982441787275',
  DeployParameterOverrides: JSON.stringify({
    HostedZoneId: hostedZoneId,
    DomainName: domainName,
    EnvironmentName: environmentName,
    Layer0DnsRecordName,
    Layer0DnsRecordType,
    Layer0DnsRecordValue,
    Layer0DnsSslChallengeName,
    Layer0DnsSslChallengeValue,
    BigCommerceDnsSslChallengeName,
    BigCommerceDnsSslChallengeValue,
    MerchantHostedZoneId,
    PublicCdnDnsName,
    StaticsBucketDnsName,
    PublicCdnHostedZoneId,
  }),
  BOPUS_FIELD: 'field_30',
  STORE_CREDITS_APPLIED_FIELD: 'field_38',
  CUSTOM_FIELD_REWARD_EARNED: 'field_36',
  BLOOMREACH_ACCOUNT_ID: 7294,
  IS_BLOOMREACH_TEST_ENV: false,
  FEATURE_TOGGLES: setFeatureToggles(environmentName),
  CRITEO_ACCOUNT_ID: '12345',
  VITALS_LOGGING_API: 'https://3diyhy88wk.execute-api.us-east-1.amazonaws.com/Prod',
  PLACES_KEY: 'AIzaSyDepQne-a9_rYklm3nwynCKQ3CuGFD97GI',
  HOTJAR_ID: '1548946',
  LISTRAK_ID: 'T6phidacCJdz',
  WUNDERKIND_ID: '3212',
  EXTEND_ID: 'ffd16de5-7a43-462d-a357-02f41d6847ec',
  EXTEND_ENVIRONMENT: 'production',
  EXTEND_KEY: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImUxNDk5MTYxLWJlZDEtNGE1YS04NzRkLWMxMjAzYzU4ZGQ5NyJ9.eyJlbWFpbCI6ImFudW5lc0BvbmVraW5nc2xhbmUuY29tIiwiYWNjb3VudElkIjoiZjViMTg0MzgtYzdiZi00ZDdiLWJhZTMtZjI4OTE5ODFiODBlIiwiZ3R5IjoicGFzc3dvcmQiLCJzY29wZSI6ImRlZmF1bHQiLCJpYXQiOjE3MTA1MzcyMDcsImV4cCI6MjU1MTEyODM4MDA2LCJpc3MiOiJhcGkuaGVsbG9leHRlbmQuY29tIiwic3ViIjoiNTNkZTAzNDMtZGZiNi00MDliLTk3YjUtZTk2NTIwMWRkYzg1IiwianRpIjoiaTJlcyhsVDt0PmQ_VEFvTHVjYE0ifQ.a3Ro08X0uzxJX8pTxrVYc-RPzzBM3vTDQf7cd3j8URoYbL22V0uIGwGQR7oFXvkOFHK3xUxVAYYukTVH7QbRlw',
  CITIZENS_PAY_EXPERIMENT_ID: 'HvQ_ItYNSBKLqRKpIgOM5w',
  SHOP_TOKEN_SCRIPT: 'https://web-js.bigshoptoken.com/main.js?retailer-name=okl&platform=big-commerce',
  SENTRY_DSN: 'https://636b9931b0164c9f99f8d182cb8badaa@o32694.ingest.sentry.io/6611343',
  SENTRY_ENVIRONMENT: 'production',
  GraphQLStoreFrontJWT: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOjEsImNvcnMiOlsiaHR0cHM6Ly93d3cub25la2luZ3NsYW5lLmNvbSIsImh0dHBzOi8vb25la2luZ3NsYW5lLmNvbSJdLCJlYXQiOjIxNDc0MDM1OTksImlhdCI6MTY1ODQyMDI5NSwiaXNzIjoiQkMiLCJzaWQiOjEwMDE2MjE0NTYsInN1YiI6ImlydXZrbXFjN2l2aTJqdWR2YTQ1cTJ4OWlyemVsZjMiLCJzdWJfdHlwZSI6MiwidG9rZW5fdHlwZSI6MX0.SNXgwXi6gVumsGK14_tHbjySlLavj8tQ51EqH5lEuoEANimZIxbm0QaXmNsVCaYd_L_lZ20BMDUYNd8By6V4lA', // Expires at 2147403599 allowed 'https://www.onekingslane.com', 'https://onekingslane.com'
  OAUTH_API: '/oauth',
  ReadonlyBigCAccessToken: 'qq899o7ctmaskpbpbbskrco15mtunik',
  CSC_SHOPFRONT_API: 'api.cscshopfront.com',
  CSC_PAYMENTS_HOST: 'api.cscpaymentsservices.com',
  CSC_PAYMENTS_PK: 'pk_live_51MkGXCC8n6OBWmc38uDqEqy3xqH1MGot4KaT6tvz7QLhGPjqHAfbh7dhyXxwhlR6T8Unh3wCZYW9zbN8raActlJ500z6S9CJKt',
  CSC_PAYMENTS_GATEWAY: 'okl-production-stripe',
  CSC_APPLE_PAY_GATEWAY: 'okl-production-cybersource',
  CSC_PAYMENTS_SHOP: 'okl-production',
  CSC_IAM_AUD: 'qd5ppux5x24qdqxskhk1ecjy0oyvxk8', // UPDATE THIS
  CSC_IAM_REGISTERED_IDP: 'okl-production-registered',
  CSC_IAM_GUEST_IDP: 'okl-production-guests', // double check these too
  CSC_IAM_ACCOUNT: '000000888888',
  CSC_IAM_REGISTERED_ROLE: 'SignedInProductionOklShopFrontCustomer',
  CSC_IAM_GUEST_ROLE: 'GuestProductionOklShopFrontCustomer',
  CSC_SHOPFRONT_MERCHANDISING_IDENTITY: 'okl-website-our-picks',
  CSC_SHOPFRONT_PRODUCT_MERCHADISING_ROLE: 'OklSiteProductMerchandiser',
  FINGERPRINT_ORG_ID: 'onekingslane_w20p',
  FINGERPRINT_MERCHANT_ID: 'opco_master_acct',
  GUEST_TOKEN_NAME: 'guestToken',
  KUSTOMER_API_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyZmQ2NDI5MzcyOGZkNGJhM2QxZTcwOSIsInVzZXIiOiI2MmZkNjQyOWYyOTA5MDE5MDliOGIxZDQiLCJvcmciOiI2Mjk2NWQxMzAxMWM3ODM5ZDcxNTZiZDciLCJvcmdOYW1lIjoiY3NjZ2VuZXJhdGlvbiIsInVzZXJUeXBlIjoibWFjaGluZSIsInBvZCI6InByb2QxIiwicm9sZXMiOlsib3JnLnRyYWNraW5nIl0sImF1ZCI6InVybjpjb25zdW1lciIsImlzcyI6InVybjphcGkiLCJzdWIiOiI2MmZkNjQyOWYyOTA5MDE5MDliOGIxZDQifQ.ADtFxPD5ZVM8Yxw9TaBtyhj0Fx3XYYHo8RjvvwN32Gg',
  KUSTOMER_BRAND_ID: '62e828adaa1195daf50c2d64',
  BACK_ORDER_CUSTOM_FIELD_ID: 'field_26',
  ORDER_EXTRA_DATA_FIELD_ID: 'field_28',
  IS_BNPL_FIELD: 'field_30',
  CJ_DOMAIN: '.onekingslane.com',
  LISTRAK_SUBSCRIPTION_LIST: 'BCGuestCheckout',
  OPTIMIZE_CONTAINER_ID: 'OPT-PSHRWTR',
  APPLE_PAY_MERCHANT_ID: 'merchant.oklproduction.applepay',
  APPLE_PAY_SCRIPT: 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js',
  APPLE_PAY_EXPERIMENT_ID: 'KTbNpcOQRCqgq99nbruJrg',
  PAYPAL_EXPERIMENT_ID: 'kXNyalHzQseFXo3CYsFj2w',
  VWO_ACCOUNT_ID: '681566',
  CONNEXITY_ACCOUNT_ID: '253757',
  PROMO_EXPERIMENT_ID: 'xmzzBGXkRwiTWc7RsPKwDg',
  DISABLE_CC: false,
  ARRIVAL_DATE_EXPERIMENT_ID: 'cqG6oE4HRPmsyCetfc6G4A',
  SHIPPEDSHIELD_EXPERIMENT_ID: 'WA-KREwMQPuqzFnxR_zFDQ',
  PAYPAL_ID: 'AYsiYwpOx4Hka04DAwx6jArKxUQiOh7CbR1P30R44d7qeHuub9E5Mwr55-QeZDtp19bcb_gTttLN2K7g',
  TURNTO_SITE_KEY: 'CcgOf7OFYBf2iHdsite',
  SHIPPING_RATE_API: 'https://6q9owby5pb.execute-api.us-east-1.amazonaws.com/Prod',
  USE_LOCAL_CSC_CREDENTIALS: false,
  ORDER_METADATA_FIELD_ID: 'field_40',
  FREE_SHIPPING_ELIGIBLE: 'field_32',
  SMS_OPT_IN_FIELD_ID: 'field_42',
  PUBLIC_DNS_CDN_NAME: PublicCdnDnsName,
  CDN_API: CDN_PREFIX,
  SEZZLE_MERCHANT_ID: '78252eb3-a9da-4144-9167-e22e57c0d78a',
}
