// eslint-disable-next-line no-unused-vars
const defaultToggles = {
  isVWOEnabled: true,
  payPalEnabled: false,
  minicartApplePayCtaEnabled: true,
  pdpPayPalCtaEnabled: false,
  cartPayPalCtaEnabled: true,

  applePayEnabled: true,
  miniCartApplePayCtaEnabled: true,
  pdpApplePayCtaEnabled: true,
  cartApplePayCtaEnabled: true,

  checkoutApplePayCtaEnabled: true,
  checkout_isBopusEnabled: false,
  sitewide_isContentfulEnabled: true,
  sitewide_isKustomerEnabled: true,
  showFreeShippingLabel: false,
  isSkipCartEnabled: false,
  isLoggingEnabled: true,
  ltkPdpRecEnabled: true,
  collectionPdpEnabled: false,
  promoEnabled: true,
  isTurnToEnabled: true,
  isCSChatEnabled: true,
  isStripeApplePayEnabled: true,
  isStripePDPApplePayEnabled: true,
  isStripeCartApplePayEnabled: true,
  isStripeCheckoutApplePayEnabled: true,
  isStripeMiniCartApplePayEnabled: true,
  forceApplePayOn: false,
  isFreeShippingEnabled: false,
  isLinkEnabled: true,
  shouldShowBackorderBadge: false,
  enableBCGQLHydration: true,
  // enableGQLProductMatchByVariantSKU could be on after phasing out ES product-search
  enableGQLProductMatchByVariantSKU: false,
  isLoyaltyProgramEnabled: true,
  guestZgoldEnrollmentEnabled: true,
  isExtendWarrantyEnabled: true,
  isCitizensPayEnabled: false,
  enableOrderMetadata: true,
  isPDPCitizensPayEnabled: false,
  isDMPLoggingEnabled: false,
  isGetSkuFromCDNEnabled: true,
  isContentfulFooterEnabled: true,
  isAddShoppersEnabled: true,
  isSMSOptInEnabled: true,
  isLoyaltyProgramSignInEnabled: true,
  isWishlistForcedOn: true,
  listrakAPIEnabled: true,
  sezzleEnabled: true,
  isCheckoutLinkEnabled: true,
  isCheckoutGooglePayEnabled: true,
  worryFreeDelivery: true,
  eventBanner: false,
  freeRugDoorstepShipping: false,
  singleItemShipments: false,
}
const manualEnvironmentOverrides = {
  local: {
    disableSKUHealthSecurityCheck: true, // Allow an internal API to bypass a query param check
    listrakAPIEnabled: false,
    sezzleForceOn: true,
  },
  staging: {
    sezzleForceOn: true,
  },
  production: {
    isLoggingEnabled: false,
  },
}

const setFeatureToggles = (environmentName) => (
  {
    ...defaultToggles,
    ...manualEnvironmentOverrides[environmentName],
  }
)

module.exports = setFeatureToggles
